import * as React from "preact";
import { JSX } from "preact";

export interface IconContext {
  color?: string;
  size?: string;
  class?: string;
  stroke?: string | JSX.SignalLike<string | undefined>; // allow overide stroke color "currentColor"
  fill?: string | JSX.SignalLike<string | undefined>; // allow overide fill color "currentColor"
  strokeWidth?:  number | string | JSX.SignalLike<number | string | undefined>; // allow overide strokeWidth default 0

  style?: JSX.CSSProperties;
  attr?: JSX.SVGAttributes<SVGSVGElement>;
}

export const defaultContext: IconContext = {
  color: undefined,
  size: undefined,
  class: undefined,
  style: undefined,
  attr: undefined,
};

export const defaultIconContext: React.Context<IconContext> = React.createContext && React.createContext(defaultContext);

// denoCacheMetadata={"headers":{"content-type":"text/plain; charset=utf-8","vary":"Accept-Encoding","cache-control":"public, max-age=31536000, s-maxage=31536000, immutable","etag":"W/\"32a-Bmotoc1mE42JAxp+yFUNnwalsPg\"","accept-ranges":"bytes","alt-svc":"h3=\":443\"; ma=86400","strict-transport-security":"max-age=31536000; includeSubDomains; preload","cross-origin-resource-policy":"cross-origin","timing-allow-origin":"*","access-control-expose-headers":"*","x-served-by":"cache-fra-etou8220096-FRA, cache-lga21959-LGA","date":"Thu, 21 Nov 2024 13:00:24 GMT","report-to":"{\"endpoints\":[{\"url\":\"https:\\/\\/a.nel.cloudflare.com\\/report\\/v4?s=rkbZA8EeqCFFHSi5pNBMqSV3HUeyiztTFMaa6Q8JNcl3Hj6bZC5MCJS2OMidetVJyOpVdR%2BdmZD%2FhX9brr1TfyjGVl3zLaId1jt88VuUmOHVyapMAu8Ao0BMD9RyOpQY%2FDg%3D\"}],\"group\":\"cf-nel\",\"max_age\":604800}","x-content-type-options":"nosniff","nel":"{\"success_fraction\":0.01,\"report_to\":\"cf-nel\",\"max_age\":604800}","x-jsd-version":"1.0.8","cf-cache-status":"HIT","x-jsd-version-type":"version","cf-ray":"8e60dccafcb9698a-CDG","access-control-allow-origin":"*","server":"cloudflare","x-cache":"HIT, HIT"},"url":"https://cdn.jsdelivr.net/gh/urielch/react-icons@1.0.8/lib/iconContext.ts","time":1732194024}